.sideNavBar {
  width: 72px;
  max-width: 200px;
  height: calc(100vh - 72px);
  background: var(--color-bg-secondary);
  position: sticky;
  top: 72px;
  z-index: 10000;
  border-right: 1px dotted var(--color-border-secondary);
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.1s ease;
  box-sizing: border-box;
}

.miniView {
  position: fixed;
}

.expanded {
  width: 100%;
}

@media (max-width: 1024px) {
  .sideNavBar {
    display: none;
    width: 50px;
    position: fixed;
    background: transparent;
    overflow-y: unset;
  }

  .sideNavBar.mobileOpen {
    display: flex;
    width: 200px;
    height: 100vh;
    top: 0;
    background: var(--color-bg-primary);
    z-index: 150;
  }

  .navigationContainer {
    display: none;
  }

  .navigationContainer.mobileOpen {
    display: flex;
  }
}
